// Colors
$silver: #aaa;
$true-black: #000;
$house-black: #111;
$houseBlue: #00aeef;
$link: #1e7ac8;
$pewter: #444;
$tin: #333;
$steal: #999;
$cotton: #e2e0dc;
$lapis: #2349b6;
$pine: #419b6c;
$khaki: #eae8e5;
$oatmeal: #f7f5f2;
$porcelain: #f4f4f4;
$snow: #fafafa;
/* New */
$carbon: #27282a;
$input: #d6d8dd;
$dark-line: rgba(0,0,0,0.3);
$line: rgba(0,0,0,0.1);

$bergamot: #ffd300; /* RGB 255, 211, 0 */
$bergamot-peel: #ffc600;
$orange: #ffba00;
$grey-grade-3: #4b4b4b;
$grey-grade-5: #7f7f7f;
$grey-grade-7: #a2a2a2;
$newsprint: #a2a2a2;
$grey-grade-10: #e5e5e5;
$grey-grade-11: #f4f4f4;
$muslin: #f6f4f0;
/* Colors */
$jade: #60caad; /* 96, 202, 173 */
$zinc: #2a2a2a;
$pewter: #444;
$tarnish: #565553;
$stone: #7b7976;
$newsprint: #a2a19e;
$sand: #e2e0dc;
$muslin: #f6f4f0;
$cinnabar: #dd3747;
$poppy: #f0440f;

/* Consultant Colors */
$green: #63917a;
$bronze: #c89192;
$silver: #9fadc8;
$gold: #f0cc5d;
$platinum: #85617e;
$master: #3f4357;

$jade: #ffd300;

$blue: #2349b6;
$green: #419b6c;

/* Redesign colors */
$pewter: #5c5e63;

$colorPrimary: $orange;
$colorSecondary: $blue;

.color-primary {
    color: $colorPrimary;
}

.color-secondary {
    color: $colorSecondary;
}

@mixin responsive-image() {
    width: 100%;
    max-width: 100%;
    display: block;
}
@mixin standard-box-with-shadow() {
    @include border-radius(5px);
    @include box-shadow(0, 2px, 8px, 0, rgba(0, 0, 0, 0.3));
    background: #fff;
}
@mixin pseudo() {
    @include pointer-events();
    display: block;
    content: "";
}
@mixin pointer-events() {
    pointer-events: none;
}
@mixin rich-shadow() {
    @include box-shadow(0, 12px, 30px, 0, rgba(0, 0, 0, 0.2));
}
@mixin base-layout() {
    @media screen and (min-width: 1661px) {
        width: 1440px;
        margin-left: auto;
        margin-right: auto;
    }
    @media screen and (max-width: 1660px) and (min-width: 480px) {
        margin-left: 8%;
        margin-right: 8%;
    }
    @media screen and (max-width: 480px) {
        margin-left: 4%;
        margin-right: 4%;
    }
}

/* Standard lines */
@mixin border($direction: top) {
    @if $direction == top {
        border-top: 1px solid rgba(0,0,0,0.1);
    }
    @else if $direction == right {
        border-right: 1px solid rgba(0,0,0,0.1);
    }
    @else if $direction == bottom {
        border-bottom: 1px solid rgba(0,0,0,0.1);
    }
    @else if $direction == left {
        border-left: 1px solid rgba(0,0,0,0.1);
    }
}

/* Newer */
@mixin flex() {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
}
/* Older */
@mixin display-flex() {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
}
@mixin box-shadow(  $offset-x,
                    $offset-y,
                    $blur-radius,
                    $spread-radius,
                    $color,
                    $inset: null
                    ) {
    box-shadow: $inset $offset-x $offset-y $blur-radius $spread-radius $color;
    -moz-box-shadow: $inset $offset-x $offset-y $blur-radius $spread-radius $color;
    -webkit-box-shadow: $inset $offset-x $offset-y $blur-radius $spread-radius $color;
}
@mixin box-shadow-multiple($shadow...) {
    box-shadow: $shadow;
    -moz-box-shadow: $shadow;
    -webkit-box-shadow: $shadow;
}
@mixin box-shadow-none() {
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}

@mixin rotate($degree) {
    -webkit-transform: rotate($degree);
    -moz-transform: rotate($degree);
    -ms-transform: rotate($degree);
    transform: rotate($degree);
}
@mixin transform-origin($horizontal, $vertical) {
    -webkit-transform-origin: $horizontal $vertical;
    -moz-transform-origin: $horizontal $vertical;
    transform-origin: $horizontal $vertical;
}
@mixin transform($horizontal, $vertical) {
    -webkit-transform-origin: $horizontal $vertical;
    -moz-transform-origin: $horizontal $vertical;
    transform-origin: $horizontal $vertical;
}
@mixin transition($property, $speed, $easing: cubic-bezier(0.175, 0.885, 0.32, 1.275)) {
    -webkit-transition: $property $speed $easing;
    -moz-transition: $property $speed $easing;
    transition: $property $speed $easing;
}
@mixin transform($value) {
    -webkit-transform: $value;
    -moz-transform: $value;
    transform: $value;
}
@mixin limit-width-1920() {
    @media screen and (min-width: 1920px) {
        max-width: 1800px;
        margin-right: auto;
        margin-left: auto;
    }
}
/* Sagona */
/* Thin: 100 */
@font-face {
    font-family: "Sagona";
    src:
        url("/assets/typefaces/sagona/Sagona-Thin.woff2") format("woff2"),
        url("/assets/typefaces/sagona/Sagona-Thin.woff") format("woff");
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: "Sagona";
    src:
        url("/assets/typefaces/sagona/sagona-thin-italic.woff2") format("woff2"),
        url("/assets/typefaces/sagona/sagona-thin-italic.woff") format("woff");
    font-weight: 100;
    font-style: italic;
}
/* Extra Light: 200 */
@font-face {
    font-family: "Sagona";
    src:
        url("/assets/typefaces/sagona/Sagona-ExtraLight.woff2") format("woff2"),
        url("/assets/typefaces/sagona/Sagona-ExtraLight.woff") format("woff");
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: "Sagona";
    src:
        url("/assets/typefaces/sagona/sagona-extralight-italic.woff2") format("woff2"),
        url("/assets/typefaces/sagona/sagona-extralight-italic.woff") format("woff");
    font-weight: 200;
    font-style: italic;
}
/* Light: 300 */
@font-face {
    font-family: "Sagona";
    src:
        url("/assets/typefaces/sagona/Sagona-Light.woff2") format("woff2"),
        url("/assets/typefaces/sagona/Sagona-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: "Sagona";
    src:
        url("/assets/typefaces/sagona/sagona-light-italic.woff2") format("woff2"),
        url("/assets/typefaces/sagona/sagona-light-italic.woff") format("woff");
    font-weight: 300;
    font-style: italic;
}
/* Book: 400 */
@font-face {
    font-family: "Sagona";
    src:
        url("/assets/typefaces/sagona/sagona-book.woff2") format("woff2"),
        url("/assets/typefaces/sagona/sagona-book.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "Sagona";
    src:
        url("/assets/typefaces/sagona/sagona-book-italic.woff2") format("woff2"),
        url("/assets/typefaces/sagona/sagona-book-italic.woff") format("woff");
    font-weight: 400;
    font-style: italic;
}
/* Medium: 500 */
@font-face {
    font-family: "Sagona";
    src:
        url("/assets/typefaces/sagona/Sagona-Medium.woff2") format("woff2"),
        url("/assets/typefaces/sagona/Sagona-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "Sagona";
    src:
        url("/assets/typefaces/sagona/sagona-medium-italic.woff2") format("woff2"),
        url("/assets/typefaces/sagona/sagona-medium-italic.woff") format("woff");
    font-weight: 500;
    font-style: italic;
}
/* Semibold: 600 */
@font-face {
    font-family: "Sagona";
    src:
        url("/assets/typefaces/sagona/Sagona-SemiBold.woff2") format("woff2"),
        url("/assets/typefaces/sagona/Sagona-SemiBold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: "Sagona";
    src:
        url("/assets/typefaces/sagona/sagona-semibold-italic.woff2") format("woff2"),
        url("/assets/typefaces/sagona/sagona-semibold-italic.woff") format("woff");
    font-weight: 600;
    font-style: italic;
}
/* Bold: 700 */
@font-face {
    font-family: "Sagona";
    src:
        url("/assets/typefaces/sagona/sagona-bold.woff2") format("woff2"),
        url("/assets/typefaces/sagona/sagona-bold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: "Sagona";
    src:
        url("/assets/typefaces/sagona/sagona-bold-italic.woff2") format("woff2"),
        url("/assets/typefaces/sagona/sagona-bold-italic.woff") format("woff");
    font-weight: 700;
    font-style: italic;
}
/* Extra Bold: 800 */
@font-face {
    font-family: "Sagona";
    src:
        url("/assets/typefaces/sagona/sagona-extrabold.woff2") format("woff2"),
        url("/assets/typefaces/sagona/sagona-extrabold.woff") format("woff");
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: "Sagona";
    src:
        url("/assets/typefaces/sagona/sagona-extrabold-italic.woff2") format("woff2"),
        url("/assets/typefaces/sagona/sagona-extrabold-italic.woff") format("woff");
    font-weight: 800;
    font-style: italic;
}
/* Heavy (Black): 900 */
@font-face {
    font-family: "Sagona";
    src:
        url("/assets/typefaces/sagona/sagona-heavy.woff2") format("woff2"),
        url("/assets/typefaces/sagona/sagona-heavy.woff") format("woff");
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: "Sagona";
    src:
        url("/assets/typefaces/sagona/sagona-heavy-italic.woff2") format("woff2"),
        url("/assets/typefaces/sagona/sagona-heavy-italic.woff") format("woff");
    font-weight: 900;
    font-style: italic;
}

/* Patron */
/* Thin: 100 */
@font-face {
    font-family: "Patron";
    src:
        url("../assets/typefaces/patron/PatronWEB-Thin.woff2") format("woff2"),
        url("../assets/typefaces/patron/PatronWEB-Thin.woff") format("woff");
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: "Patron";
    src:
        url("../assets/typefaces/patron/PatronWEB-ThinItalic.woff2") format("woff2"),
        url("../assets/typefaces/patron/PatronWEB-ThinItalic.woff") format("woff");
    font-weight: 100;
    font-style: italic;
}
/* Light: 300 */
@font-face {
    font-family: "Patron";
    src:
        url("../assets/typefaces/patron/PatronWEB-Light.woff") format("woff"),
        url("../assets/typefaces/patron/PatronWEB-Light.woff2") format("woff2");
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: "Patron";
    src:
        url("../assets/typefaces/patron/PatronWEB-LightItalic.woff") format("woff"),
        url("../assets/typefaces/patron/PatronWEB-LightItalic.woff2") format("woff2");
    font-weight: 300;
    font-style: italic;
}
/* Regular or Book: 400 */
@font-face {
    font-family: "Patron";
    src:
        url("../assets/typefaces/patron/PatronWEB-Regular.woff") format("woff"),
        url("../assets/typefaces/patron/PatronWEB-Regular.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "Patron";
    src:
        url("../assets/typefaces/patron/PatronWEB-Italic.woff") format("woff"),
        url("../assets/typefaces/patron/PatronWEB-Italic.woff2") format("woff2");
    font-weight: 400;
    font-style: italic;
}
/* Medium: 500 */
@font-face {
    font-family: "Patron";
    src:
        url("../assets/typefaces/patron/PatronWEB-Medium.woff") format("woff"),
        url("../assets/typefaces/patron/PatronWEB-Medium.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "Patron";
    src:
        url("../assets/typefaces/patron/PatronWEB-MediumItalic.woff") format("woff"),
        url("../assets/typefaces/patron/PatronWEB-MediumItalic.woff2") format("woff2");
    font-weight: 500;
    font-style: italic;
}
/* Bold: 700 */
@font-face {
    font-family: "Patron";
    src:
        url("../assets/typefaces/patron/PatronWEB-Bold.woff") format("woff"),
        url("../assets/typefaces/patron/PatronWEB-Bold.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: "Patron";
    src:
        url("../assets/typefaces/patron/PatronWEB-BoldItalic.woff") format("woff"),
        url("../assets/typefaces/patron/PatronWEB-BoldItalic.woff2") format("woff2");
    font-weight: 700;
    font-style: italic;
}
/* Black: 900 */
@font-face {
    font-family: "Patron";
    src:
        url("../assets/typefaces/patron/PatronWEB-Black.woff") format("woff"),
        url("../assets/typefaces/patron/PatronWEB-Black.woff2") format("woff2");
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: "Patron";
    src:
        url("../assets/typefaces/patron/PatronWEB-BlackItalic.woff") format("woff"),
        url("../assets/typefaces/patron/PatronWEB-BlackItalic.woff2") format("woff2");
    font-weight: 900;
    font-style: italic;
}

@mixin absolute-suite($top: 0, $right: 0, $bottom: 0, $left: 0) {
    position: absolute;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
}
@mixin tabular-figures() {
    /* Use tabular figures for numbers */
    font-feature-settings: 'tnum' 1;
}
@mixin opacity($opacity) {
    opacity: $opacity;
}
@mixin border-radius($radius) {
    -moz-border-radius: $radius;
    -webkit-border-radius: $radius;
    border-radius: $radius;
}
@mixin uppercase($letterspacing: 3px) {
    text-transform: uppercase;
    letter-spacing: $letterspacing;
}
@mixin inline-block {
    display: inline-block;
    vertical-align: top;
}
@mixin ui-small-caps($weight: null) {
    @include uppercase();
    font-size: 16px;
    line-height: 16px;
    @if $weight == book {
        font-weight: 400;
    }
    @else {
        font-weight: 500;
    }
}
@mixin subtitle($weight: null) {
    @include ui-small-caps();
    color: $true-black;
}
@mixin mobile-fonts($size){
    @if $size == heading {
        font-size: 36px;
        line-height: 44px;
    }
    @else if $size == subheading {
        font-size: 30px;
        line-height: 36px;
    }
    @else if $size == descriptionHero {
        font-size: 14px;
        line-height: 34px;
    }
    @else if $size == introductory {
        font-size: 22px;
        line-height: 34px;
    }
    @else if $size == caption {
        /* Not used */
    }
    @else if $size == base {
        font-size: 16px;
        line-height: 27px;
    }
}
/*
@mixin title($type: large) {
    font-weight: 900;
    color: $house-black;
    @if $type == large {
        font-size: 60px;
        line-height: 68px;
    }
    @else if $type == small {
        font-size: 36px;
        line-height: 42px;
    }
    @else if $type == extra-small {
        font-size: 28px;
        line-height: 36px;
    }
    @media screen and (max-width: 640px) {
        @if $type == large {
            font-size: 45px;
            line-height: 56px;
        }
        @else if $type == small {
            font-size: 24px;
            line-height: 34px;
        }
        @else if $type == extra-small {
            font-size: 24px;
            line-height: 34px;
        }
    }
}
*/
@mixin small-caps($type: standard) {
    @include uppercase();
    font-weight: 600;
    font-size: 12px;
    @if $type == standard {
        color: $pewter;
    }
}
@mixin title($type: large) {
    font-family: "Empirica", serif;
    font-weight: 100;
    color: $house-black;
    @if $type == maxi {
        font-size: 124px;
        line-height: 124px;
        font-weight: 100;
        color: $lapis;
        @media screen and (max-width: 480px) {
            font-size: 36px;
            line-height: 36px;
        }
    }
    @if $type == xxlarge {
        font-size: 96px;
        line-height: 102px;
        @media screen and (max-width: 480px) {
            font-size: 36px;
            line-height: 36px;
        }
    }
    @if $type == xlarge {
        font-size: 64px;
        line-height: 68px;
        @media screen and (max-width: 480px) {
            font-size: 36px;
            line-height: 36px;
        }
    }
    @if $type == large {
        font-size: 52px;
        line-height: 56px;
        @media screen and (max-width: 480px) {
            font-size: 30px;
            line-height: 32px;
        }
    }
    @if $type == large-secondary {
        font-size: 52px;
        line-height: 56px;
        color: $pewter;
        @media screen and (max-width: 480px) {
            font-size: 30px;
            line-height: 32px;
        }
    }
    @if $type == medium {
        font-size: 28px;
        line-height: 32px;
        @media screen and (max-width: 480px) {
            font-size: 20px;
            line-height: 24px;
        }
    }
    @if $type == small {
        font-size: 24px;
        line-height: 28px;
        @media screen and (max-width: 480px) {
            font-size: 20px;
            line-height: 24px;
        }
    }
    @if $type == xsmall {
        font-size: 18px;
        line-height: 24px;
        @media screen and (max-width: 480px) {
            font-size: 20px;
            line-height: 24px;
        }
    }
    @if $type == small-caps {
        @include small-caps();
        font-family: "Fira", "Avenir Medium", Avenir, sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
    }
}
@mixin description($type: medium) {
    color: $carbon;
    @if $type == maxi {
        font-weight: 200;
        font-size: 36px;
        line-height: 48px;
        @media screen and (max-width: 480px) {
            font-size: 28px;
            line-height: 34px;
        }
    }
    @if $type == xlarge {
        font-weight: 300;
        font-size: 28px;
        line-height: 40px;
        @media screen and (max-width: 480px) {
            font-size: 20px;
            line-height: 28px;
        }
    }
    @if $type == large {
        font-size: 20px;
        line-height: 28px;
        font-weight: 400;
        @media screen and (max-width: 480px) {
            font-size: 16px;
            line-height: 22px;
        }
    }
    @if $type == medium {
        font-size: 18px;
        line-height: 26px;
        font-weight: 500;
        @media screen and (max-width: 480px) {
            font-size: 16px;
            line-height: 22px;
        }
    }
    @if $type == small {
        font-size: 16px;
        line-height: 26px;
        font-weight: 400;
        color: $pewter;
        @media screen and (max-width: 480px) {
            font-size: 16px;
            line-height: 22px;
        }
    }
    @if $type == xsmall {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        @media screen and (max-width: 480px) {
            font-size: 14px;
            line-height: 20px;
        }
    }
    @if $type == note {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        @media screen and (max-width: 480px) {
            font-size: 14px;
            line-height: 20px;
        }
    }
    @if $type == small-caps {
        @include small-caps();
        margin-bottom: 10px;
    }
}
/*
@mixin description($type: large) {
    color: $grey-grade-5;
    @if $type == large {
        font-weight: 300;
        font-size: 28px;
        line-height: 40px;
    }
    @else if $type == small {
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
    }
    @media screen and (max-width: 640px) {
        @if $type == large {
            font-size: 19px;
            line-height: 29px;
        }
        @else if $type == small {
            font-size: 15px;
            line-height: 26px;
        }
    }
}
*/
@mixin running-text() {
    color: $grey-grade-5;
    font-size: 16px;
    line-height: 28px;
    margin: 10px 0;
    &:first-of-type {
        margin: 0;
    }
    &:last-of-type {
        margin-bottom: 0;
    }
}
@mixin note-text() {
    font-weight: 500;
    color: $grey-grade-7;
    font-size: 14px;
    line-height: 20px;
    a {
        color: $grey-grade-3;
    }
}
@mixin title-starter() {
    font-family: Sagona;
    font-weight: 100;
    color: $zinc;
    font-size: 64px;
    line-height: 72px;
    >em {
        font-weight: inherit;
        font-style: inherit;
        background: $bergamot;
    }
    @media screen and (max-width: 640px) {
        @include mobile-fonts(heading);
    }
}
@mixin button-standard($type: null) {
    @include ui-small-caps;
    display: block;
    color: #fff;
    background-color: $house-black;
    border-radius: 3px;
    padding: 17px 15px;
    cursor: pointer;
    &:hover {
        background-color: $bergamot;
    }
    &:active {
        @include opacity(0.5);
    }
}
@mixin button-new($type: standard) {
    @include border-radius(5px);
    @include transition($property: all, $speed: 400ms);
    @include description(small);
    font-family: "Fira", "Avenir Medium", Avenir, sans-serif;
    font-weight: 600;
    display: inline-block;
    text-align: center;
    background: $lapis;
    padding: 12px 17px 10px;
    white-space: nowrap;
    @if $type == plain {
        @include border-radius(0);
        border: 0;
        margin: 0;
        font-size: inherit;
        line-height: inherit;
        text-align: inherit;
        padding: 0;
        background: none;
        color: $lapis;
        &:hover {
            color: $bergamot;
        }
        &:active {
            color: $bergamot-peel;
        }
    }
    @if $type == ecommerce {
        background: $pine;
        color: #fff;
        &:hover {
            @include box-shadow(0, 10px, 20px, -10px, rgba(66, 88, 217, 0.8));
            background-color: $bergamot;
            border-color: $bergamot;
        }
        &:active {
            @include box-shadow(0, 5px, 20px, -10px, rgba(48, 64, 155, 0.9));
            background-color: $bergamot-peel;
            border-color: $bergamot-peel;
        }
    }
    @if $type == standard {
        background: $lapis;
        color: #fff;
        &:hover {
            @include box-shadow(0, 10px, 20px, -10px, rgba(255, 211, 0, 0.9));
            background-color: $bergamot;
            border-color: $bergamot;
        }
        &:active {
            @include box-shadow(0, 5px, 20px, -10px, rgba(255, 198, 0, 0.9));
            background-color: $bergamot-peel;
            border-color: $bergamot-peel;
        }
    }
    @if $type == minor {
        @include description(small);
        color: $lapis;
        position: relative;
        display: inline-block;
        text-align: left;
        padding: 0 14px 0 0;
        white-space: nowrap;
        background: none;
        span {
            @include pseudo();
            position: absolute;
            top: 5px;
            right: 0;
            bottom: 0;
            width: 7px;
            height: 11px;
            background-repeat: no-repeat;
            background-position: 0 0;
            background-image: url("/assets/images/shared/arrow_e_4258d9.svg");
            @include background-size(7px 11px);
        }
    }
    @if $type == minor-left {
        @include description(small);
        color: $lapis;
        position: relative;
        display: inline-block;
        text-align: left;
        padding: 0 0 0 14px;
        white-space: nowrap;
        background: none;
        span {
            @include pseudo();
            position: absolute;
            top: 5px;
            left: 0;
            bottom: 0;
            width: 7px;
            height: 11px;
            background-repeat: no-repeat;
            background-position: 0 0;
            background-image: url("/assets/images/shared/arrow_w_4258d9.svg");
            @include background-size(7px 11px);
        }
    }
    @if $type == ghost {
        background: transparent;
        color: $lapis;
        border: 2px solid $lapis;
        padding: 10px 15px 8px;
        &:hover {
            @include box-shadow(0, 10px, 20px, -10px, rgba(66, 88, 217, 0.8));
            background-color: $bergamot;
            border-color: $bergamot;
            color: #fff;
        }
        &:active {
            @include box-shadow(0, 5px, 20px, -10px, rgba(48, 64, 155, 0.9));
            background-color: $bergamot-peel;
            border-color: $bergamot-peel;
            color: #fff;
        }
    }
    @if $type == ghost-arrow {
        position: relative;
        background: transparent;
        color: $lapis;
        border: 2px solid $lapis;
        padding: 10px 40px 8px 15px;
        &:after {
            @include pseudo();
            position: absolute;
            top: 0;
            right: 15px;
            bottom: 0;
            margin: auto;
            width: 14px;
            height: 14px;
            background-position: 0 0;
            background-repeat: no-repeat;
            background-image: url("/assets/images/shared/arrow_ne_4258d9.svg");
            @include background-size(14px);
        }
        &:hover {
            @include box-shadow(0, 10px, 20px, -10px, rgba(66, 88, 217, 0.8));
            background-color: $bergamot;
            border-color: $bergamot;
            color: #fff;
            &:after {
                background-image: url("/assets/images/shared/arrow_ne_ffffff.svg");
            }
        }
        &:active {
            @include box-shadow(0, 5px, 20px, -10px, rgba(48, 64, 155, 0.9));
            background-color: $bergamot-peel;
            border-color: $bergamot-peel;
            color: #fff;
        }
    }
    @if $type == pill {
        @include border-radius(20px);
        background: #fff;
        color: $lapis;
        padding: 5px 15px 4px;
        &:hover {
            @include box-shadow(0, 10px, 20px, -10px, rgba(66, 88, 217, 0.8));
            background-color: $bergamot;
            color: #fff;
        }
        &:active {
            @include box-shadow(0, 5px, 20px, -10px, rgba(48, 64, 155, 0.9));
            background-color: $bergamot-peel;
            color: #fff;
        }
    }
}
@mixin button($type: null) {
    display: block;
    text-align: center;
    cursor: pointer;
    /* All-caps small button */
    @if $type == majascule {
        @include ui-small-caps();
        color: $jade;
    }
    @else if $type == minor {
        color: green;
    }
    @else if $type == minorArrow {
        @include border-radius(24px);
        @include inline-block();
        font-weight: 700;
        font-size: 15px;
        line-height: 21px;
        color: $true-black;
        position: relative;
        border: 2px solid $bergamot;
        padding: 7px 36px 8px 14px;
        .icon {
            background: url("../assets/images/shared/arrow_right_000000@2x.png") 0 0 no-repeat;
            @include background-size(15px 13px);
            display: block;
            width: 15px;
            height: 13px;
            position: absolute;
            top: 11px;
            right: 13px;
        }
        .label {
            display: block;
        }
        &:hover {
            @include opacity(1);
            background-color: $bergamot;
        }
        &:active {
            @include opacity(0.6);
        }
    }
    @else if $type == textWithArrow {
        text-align: left;
        >.title {
            font-size: 22px;
            font-weight: 600;
            display: inline-block;
            color: $pine;
        }
        >.graphic {
            position: relative;
            top: 1px;
            left: 0;
            margin-left: 3px;
            width: 18px;
            height: 18px;
            display: inline-block;
            background: url(/assets/images/shared/arrow_419b6c_right.png) no-repeat top center;
            @include background-size(18px);
            @include transition(left, 200ms, ease);
        }
        &:hover {
            >.graphic {
                left: 3px;
                animation: jiggle 400ms 0ms forwards;
            }
        }
        @media screen and (max-width: 640px) {
            >.title {
                font-size: 17px;
            }
            >.graphic {
                width: 14px;
                height: 14px;
                @include background-size(14px);
            }
        }
    }
    /* Standard button */
    @else {
        @include description();
        @include border-radius(33px);
        @include inline-block();
        color: $jade;
        position: relative;
        border: 1px solid $jade;
        padding: 15px 30px 14px;
    }
}
@mixin input() {
    padding: 8px 16px 10px;
    @include border-radius(5px);
    font-size: 18px;
    line-height: 28px;
    border: 2px solid $grey-grade-10;
    outline: 0;
    color: $grey-grade-5;
}
@mixin background-size($value: cover) {
    -webkit-background-size: $value;
    -moz-background-size: $value;
    background-size: $value;
}
%linkFeedback {
    cursor: pointer;
}
@mixin clear-fix() {
    content: "";
    display: block;
    clear: both;
}

.content-section {
    position: relative;
    padding: 130px 0;
    text-align: center;

    @media screen and (max-width: 960px) {
        padding: 60px 0;
    }

    .container {
        max-width: 1280px;
        padding: 0 20px;
        margin: auto;
    }
}

$screen-xlg: 1680px;
$screen-lg: 1400px;
$screen-md: 1200px;
$screen-sm: 1000px;
$screen-xs: 768px;
$screen-xm: 479px;

$xlg-min: 'screen and (min-width: #{$screen-xlg})';
$lg-max: 'screen and (max-width: #{$screen-xlg - 1})';
$lg-min: 'screen and (min-width: #{$screen-lg})';
$md-max: 'screen and (max-width: #{$screen-lg - 1})';
$md-min: 'screen and (min-width: #{$screen-md})';
$sm-max: 'screen and (max-width: #{$screen-md - 1})';
$sm-min: 'screen and (min-width: #{$screen-sm})';
$xs-max: 'screen and (max-width: #{$screen-sm - 1})';
$xs-min: 'screen and (min-width: #{$screen-xs})';
$xm-max: 'screen and (max-width: #{$screen-xs - 1})';
$xm-min: 'screen and (min-width: #{$screen-xm})';

.hidden-xlg {
    
    @media #{$xlg-min} {
        display: none!important;
    }
}

.visible-lg {
    display: none !important;

    @media #{$lg-min} {
        display: block !important;
    }
}

.hidden-lg {
    
    @media #{$lg-min} {
        display: none!important;
    }
}

.hidden-md {
    
    @media #{$md-min} {
        display: none!important;
    }
}

.hidden-sm {
    
    @media #{$sm-min} {
        display: none !important;
    }
}

.hidden-xs {

    @media #{$xs-min} {
        display: none !important;
    }
}

@mixin container($w: 0, $offsetL: 0, $offsetR: $offsetL) {
    width: auto;
    margin-left: auto;
    margin-right: auto;
    padding-left: $offsetL;
    padding-right: $offsetR;

    @if $w == 0 {
            max-width: none;
        } 
    @else {
        max-width: $w + $offsetL + $offsetR;
    }
    
}

@mixin radial-gradient($from, $to) {
    background: -moz-radial-gradient(center, circle cover, $from 0%, $to 100%);
    background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, $from), color-stop(100%, $to));
    background: -webkit-radial-gradient(center, circle cover, $from 0%, $to 100%);
    background: -o-radial-gradient(center, circle cover, $from 0%, $to 100%);
    background: -ms-radial-gradient(center, circle cover, $from 0%, $to 100%);
    background: radial-gradient(center, circle cover, $from 0%, $to 100%);
    background-color: $from;
}

$path-fonts: '../assets/typefaces/';

// Includes font $font-face.
@mixin font-face ($name, $file-name, $font-weight: 400, $font-style: normal) {
  @font-face {
    font-family: $name;
    src: url('#{$path-fonts}#{$file-name}.eot') format('eot'),
         url('#{$path-fonts}#{$file-name}.woff2') format('woff2'),
         url('#{$path-fonts}#{$file-name}.woff') format('woff'),;
    font-weight: $font-weight;
    font-style: $font-style;
  }
}