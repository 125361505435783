@import "library_client";


.project-snippet {
    @include base-layout();
    >a {
        display: block;
        border: 0;
        margin-bottom: 4%;
        &:hover {
            background-color: $porcelain;
            >.lockup {
                padding: 2% 3%;
            }
        }
        >.graphic {
            >img {
                max-width: 100%;
                width: 100%;
            }
        }
        >.lockup {
            @include flex();
            @include transition($property: padding, $speed: 400ms);
            justify-content: space-between;
            align-items: center;
            padding: 2% 0;
            >h2 {
                font-family: Empirica, sans-serif;
                font-size: 32px;
                font-weight: 500;
                color: $tin;
            }
            >span {
                display: block;
                width: 80px;
                height: 80px;
                background-position: 0 0;
                background-repeat: no-repeat;
                background-image: url("/assets/shared/arrow_east.svg");
                @include background-size(80px);
            }
        }
    }
}

.client-content {
    >.hero {
        @include flex();
        height: 100vh;
        position: relative;
        background: $porcelain;
        padding-top: 40px;
        min-height: 640px;
        >.lockup {
            @include base-layout();
            @include flex();
            flex-direction: column-reverse;
            justify-content: flex-start;
            width: 100%;
            >.heading {
                padding: 0 0 8%;
                >.title {
                    @include title();
                }
                >.title-secondary {
                    @include title(large-secondary);
                }
                >.description {
                    @include description();
                    margin-top: 1%;
                    color: rgba(0,0,0,0.7);
                    max-width: 960px;
                    line-height: 32px;
                }
            }
            >.graphic {
                &.cover {
                    @include background-size(contain);
                    background-position: center;
                    background-repeat: no-repeat;
                    height: 100%;
                }
            }
        }
        &.numin {
         >.lockup {
                >.graphic {
                    &.cover {
                        background-position: center left;
                    }
                }
            }   
        }
    }
    >.introduction {
        @include flex();
        margin-top: 8%;
        margin-bottom: 8%;
        >.primary {
            width: 50%;
            >.heading {
                >.title {
                    @include description();
                    font-weight: 900;
                    line-height: 32px;
                }
            }
        }
        >.secondary {
            width: 50%;
            /* This is from the implied gutter of 40px from the Data Points below it */
            padding-left: 20px;
            >.description {
                >p {
                    @include description();
                    & + p {
                        @include description(small);
                        margin-top: 4%;
                    }
                    a {
                        @include description();
                        color: $lapis;
                        border: 0;
                        &:hover {
                            color: #fff;
                            background: $lapis;
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 640px) {
            display: block;
            padding-left: 12%;
            >.primary,
            >.secondary {
                padding: 4%;
                width: 100%;
            }
        }
    }
    >.data-points {
        margin-bottom: 8%;
        @media screen and (max-width: 640px) {
            display: none;
        }
        >.value-group {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 40px;
            >.value-container {
                border-top: 1px solid $line;
                >.label {
                    @include description();
                    padding-top: 30px;
                    min-height: 110px;
                }
                >.value {
                    @include title(maxi);
                    line-height: normal;
                    text-indent: -4px;
                    font-family: "Fira";
                    font-weight: 100;
                    >span {
                        font-size: 48px;
                        font-weight: 200;
                        padding-left: 5px;
                    }
                }
                >.description {
                    @include description(small);
                    margin-top: -10px;
                    color: $newsprint;
                }
                &:nth-of-type(1) {
                    grid-column-start: 1;
                    grid-column-end: 3;
                    grid-row-start: 1;
                    grid-row-end: 2;
                }
                &:nth-of-type(5) {
                    grid-column-start: 3;
                    grid-column-end: 4;
                    grid-row-start: 2;
                    grid-row-end: 3;
                }
                &:nth-of-type(6) {
                    grid-column-start: 4;
                    grid-column-end: 5;
                    grid-row-start: 2;
                    grid-row-end: 3;
                }
                &:nth-of-type(4) {
                    grid-column-start: 1;
                    grid-column-end: 3;
                    grid-row-start: 2;
                    grid-row-end: 3;
                }
            }
        }
    }
    >.project-piece {
        margin-bottom: 8%;
        >.primary {
            >.heading {
                margin-left: 4%;
                >.title {
                    @include title(large);
                    font-weight: 300;
                    padding-bottom: 2%;
                }
            }
        }
        >.secondary {
            >.gallery {
                &.rich-content {
                    >section {
                        margin-bottom: 20px;
                        &.color-swatches {
                            display: grid;
                            grid-gap: 20px;
                            &.test-swatches {
                                grid-template-columns: repeat(5, 1fr);
                            }
                            &.final-swatches {
                                grid-template-columns: repeat(4, 1fr);
                            }
                            >.graphic {
                                @include uppercase();
                                font-size: 12px;
                                position: relative;
                                &:after {
                                    @include pseudo();
                                    @include box-shadow(0,0,0,4px,rgba(0,0,0,0.1),inset);
                                    @include absolute-suite();
                                }
                                >.swatch {
                                    position: relative;
                                    width: 100%;
                                    border: 1px solid transparent;
                                    &:before {
                                        @include pseudo();
                                        display: block;
                                        margin-top: 100%;
                                    }
                                    >span {
                                        @include absolute-suite();
                                        display: block;
                                    }
                                }
                                >.title {
                                    padding: 20px 20px 0;
                                }
                                >.title-secondary {
                                    padding: 5px 20px 0;
                                }
                                >.hex {
                                    padding: 5px 20px 20px;
                                }
                            }
                        }
                    }
                }
                &.compound-grid {
                    display: block;
                    grid-template-columns: repeat(2, 1fr);
                    display: grid;
                    grid-gap: 1px;
                    >figure {
                        padding: 0;
                        background: $porcelain;
                        >.graphic {
                            >img {
                                @include responsive-image();
                            }
                        }
                        >figcaption {
                            padding: 1% 0 2%;
                        }
                        /*&:nth-of-type(3n+1),*/
                        &.full-width {
                            grid-column-start: 1;
                            grid-column-end: 3;
                        }
                        &.transparent {
                            background: transparent;
                        }
                    }
                }
            }
            >.project-piece-description {
                max-width: 640px;
                margin-top: 2%;
                margin-left: 4%;
                >.heading {
                    margin-bottom: 24px;
                    >.title {
                        @include title(small-caps);
                        color: $carbon;
                    }
                    >.title-secondary {
                        @include title(small-caps);
                    }
                }
                >.description {
                    h3 {
                        @include title(small-caps);
                        margin-top: 24px;
                        margin-bottom: 8px;
                    }
                    p {
                        @include description(small);
                    }
                }
            }
        }
    }
    >.testimonial {
        padding: 4% 0;
        margin-bottom: 8%;
        >blockquote {
            margin-left: 25%;
            >q {
                @include title(xxlarge);
                font-weight: 100;
                color: #000;
                position: relative;
                quotes: "“" "”" "‘" "’";
                display: block;
                >em {
                    font-weight: 100;
                    font-style: normal;
                    background: $bergamot;
                }
                &:before {
                    position: absolute;
                    top: -7px;
                    left: -100px;
                    display: block;
                    font-size: 140px;
                    line-height: 140px;
                    font-weight: 900;
                    color: $lapis;
                    height: 65px;
                    width: 65px;
                    margin-bottom: 20px;
                    font-family: "Fira", "Avenir Medium", Avenir, sans-serif;
                }
            }
            >.value-container {
                margin-top: 2%;
                >.value {
                    @include title(small-caps);
                    color: $carbon;
                }
                >.label {
                    @include title(small-caps);
                }
            }
        }
    }
    >.external-link-list {
        @include flex();
        flex-direction: column;
        align-items: flex-end;
        >.primary {
            width: 100%;
            >.heading {
                >.title {
                    @include title(maxi);
                }
            }
        }
        >.secondary {
            width: 50%;
            >.grid {
                >.item {
                    display: block;
                    margin: 6% 0;
                    >.value-container {
                        >.value {
                            >.title {   
                            @include title(medium);
                            font-family: "Fira";
                            font-weight: 400;
                            color: $carbon;
                            }
                        }
                        >.label {
                            @include title(small-caps);
                            margin-top: 2%;
                        }
                    }
                    &:hover {
                        background: transparent;
                        >.value-container {
                            >.value {
                                >.title {
                                    color: $lapis;
                                }
                            }
                            >.label {
                                color: $lapis;
                            }  
                        }
                    }
                }
            }
        }
    }
    >.similar-projects {
        margin-bottom: 8%;
        >.primary {
            >.heading {
                >.title {
                    @include title(maxi);
                }
                >.title-secondary {
                    @include title(large-secondary);
                }
            }
        }
        >.secondary {
            >.grid {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 40px;
                margin-top: 4%;
                >.item {
                    @include border-radius(5px);
                    @include flex();
                    @include box-shadow(0,12px,40px,0,rgba(35,73,182,0.70));
                    @include transition(all, 300ms);
                    position: relative;
                    top: 0;
                    flex-direction: column;
                    background: $lapis;
                    padding: 14% 8%;
                    >.heading {
                        height: 100%;
                        >.label {
                            @include title(small-caps);
                            color: $bergamot;
                        }
                        >.title {
                            @include title(large);
                            color: #fff;
                            font-weight: 300;
                            margin: 5% 0 10%;
                        }
                    }
                    >.description {
                        p {
                            @include description();
                            color: rgba(255,255,255,0.8);
                        }
                    }
                    &:hover {
                        @include box-shadow(0,30px,80px,-20px,rgba(35,73,182,0.80));
                        top: -3px;
                        cursor: pointer;
                    }
                    &:nth-of-type(2) {
                        @include box-shadow(0,12px,40px,0,rgba(65,155,109,0.70));
                        background: $pine;
                        >.heading {
                            >.label {
                                color: $bergamot;
                            }
                        }
                        &:hover {
                            @include box-shadow(0,30px,80px,-20px,rgba(65,155,109,0.80));
                        }
                    }
                    &:nth-of-type(3) {
                        @include box-shadow(0,12px,40px,0,rgba(0,0,0,0.70));
                        background: $tin;
                        >.heading {
                            >.label {
                                color: $bergamot;
                            }
                        }
                        &:hover {
                            @include box-shadow(0,30px,80px,-20px,rgba(0,0,0,0.80));
                        }
                    }
                    &:nth-of-type(4) {
                        @include box-shadow(0,12px,40px,0,rgba(0,0,0,0.20));
                        background: $porcelain;
                        >.heading {
                            >.label {
                                color: $cinnabar;
                            }
                            >.title {
                                color: $tin;
                            }
                        }
                        >.description {
                            p {
                                color: $pewter;
                            }
                        }
                        &:hover {
                            @include box-shadow(0,30px,80px,-20px,rgba(0,0,0,0.40));
                        }
                    }
                }
            }
        }
    }
    >.cta {
        &.last-call {
            text-align: left;
            >.primary {
                padding: 4% 0 0;
                >.lockup {
                    >.heading {
                        >.title {
                            @include title(large-secondary);
                            color: $lapis;
                        }
                        >.title-secondary {
                            @include title(large-secondary);
                        }
                    }
                    >.description {
                        margin-top: 3%;
                        >.button-group {
                            >a {
                                @include button(textWithArrow);
                                color: $pine;
                                background: transparent;
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 880px) {
        >.hero {
            padding-right: 0;
        }
        >.cta {
            margin: 4%;
            margin-top: calc(4% + 64px);
            >.chat-group {
                position: absolute;
                top: -44px;
                right: 4%;
                left: 4%;
                z-index: 1;
                >.chat {
                    /* Visual centering */
                    padding-left: 5%;
                    width: 100%;
                }
            }
            >.primary {
                padding: 8%;
            }
            &.fixed {
                position: relative;
                top: auto;
                right: auto;
                width: auto;
            }
            &.page-flow {
                @include base-layout();
                margin: 0 0 4%;
            }
        }
    }
}
